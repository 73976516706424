import request from '../utils/request';
import qs from "qs";

export const addCardType = param => {
	return request.post('/api/cards/addCardType', qs.stringify(param))
};
export const getCardType = param => {
	return request.get('/api/cards/getCardType', qs.stringify(param))
};
export const deleteCardType = param => {
	return request.post('/api/cards/deleteCardType', qs.stringify(param))
};
export const batchSet = param => {
	return request.post('/api/user/batchSet', qs.stringify(param))
};
export const getOrdersList = param => {
	return request.post('/api/orders/list', qs.stringify(param))
};
export const getRefundList = param => {
	return request.post('/api/topUp/refundList', qs.stringify(param))
};
export const getOrdersMakeUp = param => {
	return request.post('/api/orders/makeUp', qs.stringify(param))
};


export const downloadOrders = param => {
	return request.post('/api/orders/downloadOrders', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const getRefund = param => {
	return request.post('/api/topUp/refund', qs.stringify(param))
};
export const updateSort = param => {
	return request.post('/api/cards/updateSort', qs.stringify(param))
};